import useAxios from '@/utils/useAxios'

export interface VerificationDetailInterface {
  hash: string
  document_name: string
  reference_url: string
  created_name: string
  created: string
  source: string
}

export const useFetchVerificationDetail = async (hash: string) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/qr/fetch/${hash}/`,
    method: 'GET'
  })
    .then(() => {
      console.log('successfully fetch the data')
    })
    .catch(() => {
      console.log('Exception occur while fetching the data', error)
    })
    .finally(() => {})
  return { data, error, status }
}
