<script setup lang="ts">
import { onMounted, reactive } from 'vue'
import { useFetchVerificationDetail } from '@/services/verifications/useFetchVerificationDetail'
import { useRoute } from 'vue-router'
import { formatTimestamp } from '@/utils/helpers/formatDateTime'

const route = useRoute()

const documentDetails = reactive({
  name: '',
  verificationId: '',
  createdOn: '',
  createdBy: '',
  referenceUrl: '',
  isLoading: true,
  isDocumentExists: true,
  isDemoDomain: false
})

const fetchVerificationDetail = (hash: string) => {
  useFetchVerificationDetail(hash).then(({ data, error, status }) => {
    if (status.value == 200) {
      documentDetails.name = data.value?.document_name
      documentDetails.verificationId = data.value?.hash
      documentDetails.createdOn = formatTimestamp(data.value?.created)
      documentDetails.createdBy = data.value?.created_name
      documentDetails.referenceUrl = data.value?.reference_url
      documentDetails.isDemoDomain = data.value?.has_demo_domain
      documentDetails.isLoading = false
    }
    if (status.value == 404) {
      documentDetails.isLoading = false
      documentDetails.isDocumentExists = false
    }
    if (error.value) {
      documentDetails.isLoading = false
      documentDetails.isDocumentExists = false
    }
  })
}

onMounted(() => fetchVerificationDetail(<string>route.params.hash))
</script>

<template>
  <div class="container mx-auto grid grid-col gap-y-44">
    <!--Document Details-->
    <div class="font-jost text-qrmark-text-light bg-qrmark-secondary-main relative rounded-b-md">
      <div class="flex items-center justify-start space-x-6 bg-white/10 rounded p-1 my-16 mx-4">
        <div class="p-1 bg-white rounded">
          <img src="@/assets/img/VerifiedIcon.svg" alt="Verification Shield" />
        </div>
        <h1 class="text-xl text-white">
          <span v-if="documentDetails.isDemoDomain">Demo</span> Document Details
        </h1>
      </div>
      <!--Verification Details Card-->
      <div class="absolute top-[70%] sm:top-[90%] left-0 right-0">
        <div class="shadow-lg bg-white rounded mx-auto w-11/12">
          <!--Verification Details-->
          <div class="grid gap-2 grid-cols-2 p-4" v-if="documentDetails.isDocumentExists">
            <span>Document Name:</span>
            <span :class="documentDetails.isLoading && 'loader'" class="break-words">{{
              documentDetails.name
            }}</span>
            <span>Verification ID:</span>
            <span :class="documentDetails.isLoading && 'loader'">
              {{ documentDetails.verificationId }}
            </span>
            <span>Created on:</span>
            <span :class="documentDetails.isLoading && 'loader'">
              {{ documentDetails.createdOn }} (UTC)
            </span>
            <span>Created by:</span>
            <span :class="documentDetails.isLoading && 'loader'">
              {{ documentDetails.createdBy }}
            </span>
          </div>
          <!--No Verification Found-->
          <div class="mx-auto text-center p-10 text-lg" v-else>
            <span>No document Found.</span>
          </div>
        </div>
        <p class="mx-auto w-11/12 text-red-400 p-2" v-if="documentDetails.isDemoDomain">
          <span class="font-jost font-semibold">Note:</span> This is a demo verification page. The
          details and document shown here might not be the original and should not be treated as
          authentic.
        </p>

        <!--View Original Document Button-->
        <div class="text-center mt-12">
          <a
            class="button whitespace-nowrap"
            :href="documentDetails.referenceUrl"
            target="_blank"
            :class="!documentDetails.isDocumentExists && 'button-disabled'"
          >
            View <span v-if="!documentDetails.isDemoDomain">Original</span> Document
          </a>
        </div>

        <!--Powered by QR Mark-->
        <div class="flex justify-center items-center flex-col mt-12">
          <p class="text-gray-500 text-sm font-jost font-normal">Powered By</p>
          <a href="https://qrmark.com" target="_blank" rel="noreferrer">
            <img
              class="text-center"
              width="150"
              height="100"
              src="@/assets/img/qrmark-logo-full-text.svg"
              alt="QR Mark"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.loader {
  @apply animate-pulse bg-slate-200 rounded-md;
}
.button {
  @apply bg-qrmark-primary-light px-12 sm:px-24 py-3 text-white font-jost rounded-md mx-auto;
}
.button-disabled {
  @apply animate-pulse opacity-20 pointer-events-none cursor-default;
}
</style>
