import './assets/css/base.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import * as Sentry from '@sentry/vue'

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://45b251d6964571b9a98233f714df0947@o13089.ingest.us.sentry.io/4507706540818432',
  environment: import.meta.env.VUE_ENVIRONMENT,
  enabled: import.meta.env.VUE_ENVIRONMENT === 'production',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.01, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [import.meta.env.VUE_API_BASE_URL],
  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(router)

app.mount('#app')
