export const formatTimestamp = (timestampString: string) => {
  // Convert string to the Date object
  const timestamp = new Date(timestampString)

  // Convert to local time
  const localTime = new Date(timestamp.getTime() + timestamp.getTimezoneOffset() * 60 * 1000)

  // Format the date
  return new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(localTime)
}
