import { ref, type Ref } from 'vue'
import axios, { type AxiosRequestConfig, type AxiosResponse, type AxiosError } from 'axios'

interface UseAxiosResponse<T> {
  data: Ref<T | null>
  error: Ref<any>
  status: Ref<number>
  fetchData: (options: AxiosRequestConfig) => Promise<void>
}

/**
 * Axios request configuration.
 * https://axios-http.com/docs/req_config
 */
// Request defaults config
axios.defaults.baseURL = import.meta.env.VUE_API_BASE_URL
axios.defaults.method = 'GET'
axios.defaults.maxRedirects = 0
axios.defaults.responseType = 'json'

// Request headers config
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json'

// Request security headers config
// https://developer.okta.com/blog/2021/10/18/security-headers-best-practices
axios.defaults.headers.common['X-Frame-Options'] = 'DENY'
axios.defaults.headers.common['X-XSS-Protection'] = 0
// axios.defaults.headers.common['Content-Security-Policy'] = "default-src 'none'; " +
//     "script-src 'self' www.google-analytics.com 'unsafe-inline'; " +
//     "style-src 'self' https://fonts.googleapis.com 'unsafe-inline'; " +
//     "font-src 'self' https://fonts.gstatic.com; base-uri 'self';"
// axios.defaults.headers.common["Strict-Transport-Security"] = "max-age=31536000; includeSubDomains";

// Config global for axios/django
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true

const useAxios = <T>(): UseAxiosResponse<T> => {
  // Refs
  const data: Ref<T | null> = ref(null)
  const status: Ref<number> = ref(0)
  const error: Ref<T | AxiosError | any> = ref(null)

  const fetchData = async (options: AxiosRequestConfig) => {
    await axios
      .request(options)
      .then((response: AxiosResponse) => {
        data.value = response.data
        status.value = response.status
      })
      .catch((err: AxiosError) => {
        error.value = err.response?.data
        status.value = err.response?.status || 0
        // On token expiry there is an Unauthorized error
        // Refresh token generation if status code is 401 (Unauthorized).
        // if (err.response?.status == 401) useUnauthorizedTokenError()
      })
      .finally(() => {})
  }

  return { data, error, status, fetchData }
}

export default useAxios
